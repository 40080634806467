<template>
  <div>
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <div id="displayCard">
      <v-card min-width="600px" class="pa-10">
        <h1>Users</h1>
        <div class="searchToolbar">
          <v-text-field
            v-model="searchInput"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <div class="ma-3">
            <vue-json-to-csv :json-data="userJson" :labels="csvLabels">
              <v-btn depressed>
                Download CSV
                <v-icon right>mdi-download</v-icon>
              </v-btn>
            </vue-json-to-csv>
          </div>
          <div>
            <v-switch v-model="autoApprove" label="Auto approve" color="primary" @change="autoApproveDialog = true">
            </v-switch>
            <v-dialog v-model="autoApproveDialog" width="500" persistent>
              <v-card class="pa-10">
                <h3>Password</h3>
                <v-text-field
                  v-model="password"
                  outlined
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  @click:append="show1 = !show1"
                  class="mt-5"
                ></v-text-field>
                <div style="width: 100%; display: flex; justify-content: space-between">
                  <v-btn @click="setAutoApprove" depressed color="primary" rounded>Confirm</v-btn>
                  <v-btn @click="dismissAutoApprove" depressed color="secondary" rounded class="ml-2">Close</v-btn>
                </div>
              </v-card>
            </v-dialog>
          </div>
        </div>

        <status-code-bar :code="statusCode"></status-code-bar>
        <v-data-table
          :headers="headers.filter((header) => header.inTable)"
          :items="users"
          :search="searchInput"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          dense
        >
          <template v-slot:item.approveUser="{ item }">
            <div style="display: flex; flex-direction: row; justify-content: center" v-if="!item.isApproved">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="approveUser(item.userid)" icon color="secondary" v-bind="attrs" v-on="on">
                    <v-icon>mdi-check-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Approve</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="disapproveUser(item.userid)" icon color="red" v-bind="attrs" v-on="on">
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>Disapprove</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:item.lockUser="{ item }">
            <div>
              <v-btn @click="lockUser(item.userid)" icon v-if="!item.isLocked" color="secondary">
                <v-icon>mdi-lock-outline</v-icon>
              </v-btn>

              <v-btn @click="unlockUser(item.userid)" icon color="primary" v-if="item.isLocked">
                <v-icon>mdi-lock-open-outline</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.userid="{ item }">
            <div class="d-flex align-center">
              <avatar-from-user-id :userid="item.userid" size="40" class="my-3" />
              <p :style="getStatusColor(item)" class="ml-2">
                {{ item.userid }}
              </p>
            </div>
          </template>
          <template v-slot:item.roleInfoExists="{ item }">
            <v-icon
              class="mr-2"
              color="primary"
              @click="viewRoleInfo(item)"
              v-if="Object.getOwnPropertyNames(item.roleInfo).length !== 1"
            >
              mdi-eye-outline
            </v-icon>
          </template>
          <template v-slot:item.isPremium="{ item }">
            <span
              style="color: #8bb35d; font-weight: bold"
              v-if="new Date(item.premiumExpiryDate).getTime() - new Date().getTime() > 0"
              >Premium</span
            >
            <span class="ma-0" v-else>Free</span>
          </template>
          <template v-slot:item.premiumExpiryDate="{ item }">
            <span
              :style="`color: ${
                new Date(item.premiumExpiryDate).getTime() - new Date().getTime() > 0 ? '#8bb35d' : '#b71c1c'
              }; font-weight: bold`"
              v-if="new Date(item.premiumExpiryDate).getTime() > 0"
            >
              {{ new Date(item.premiumExpiryDate).toISOString().substr(0, 10) }}
            </span>
            <span class="ma-0" v-else>N/A</span>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{
              isNaN(new Date(item.createdAt)) ? "Invalid Date" : new Date(item.createdAt).toISOString().substr(0, 10)
            }}
          </template>
        </v-data-table>
        <v-dialog v-model="dialog" width="500">
          <v-card v-if="viewItem">
            <div v-if="!Object.hasOwnProperty.call(viewItem.roleInfo)">
              <v-expansion-panels v-if="viewItem.roleInfo" accordion flat>
                <v-expansion-panel>
                  <v-expansion-panel-header style="font-weight: 600">Personal Info</v-expansion-panel-header>
                  <v-expansion-panel-content class="px-6">
                    ID Name: {{ viewItem.roleInfo.idName }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.countryOfIssue">
                    Birthday/ BR Incorporation Date:
                    {{ new Date(viewItem.roleInfo.birthday).toISOString().substr(0, 10) }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.address">
                    Address: {{ viewItem.roleInfo.address }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="viewItem.roleInfo.idPhoto">
                  <v-expansion-panel-header style="font-weight: 600">ID Info</v-expansion-panel-header>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.idType">
                    ID Type: {{ idTypeItems(viewItem.roleInfo.idType) }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.idNumber">
                    ID Number/ BR Number: {{ viewItem.roleInfo.idNumber }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.idExpiry">
                    Passport Expiry/ BR Expiry: {{ new Date(viewItem.roleInfo.idExpiry).toISOString().substr(0, 10) }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.countryOfIssue">
                    Country of Issue: {{ viewItem.roleInfo.countryOfIssue }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6">
                    <protected-image-from-file-descriptor
                      :fileDescriptor="viewItem.roleInfo.idPhoto.fileDescriptor"
                      :key="viewItem.roleInfo.idPhoto.fileDescriptor.fileid"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header style="font-weight: 600">Payment Info</v-expansion-panel-header>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.paypalAccount">
                    Paypal: {{ viewItem.roleInfo.paypalAccount }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.fpsAccount">
                    FPS: {{ viewItem.roleInfo.fpsAccount }}
                  </v-expansion-panel-content>
                  <v-expansion-panel-content class="px-6" v-if="viewItem.roleInfo.bankAccount">
                    Bank Code: {{ viewItem.roleInfo.bankAccount.bankCode }} <br />
                    Account Number: {{ viewItem.roleInfo.bankAccount.accountNumber }}<br />
                    Account Name: {{ viewItem.roleInfo.bankAccount.accountName }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="Object.hasOwnProperty.call(viewItem.roleInfo, 'eventNameHistory')">
                  <v-expansion-panel-header>Event Viewing History</v-expansion-panel-header>
                  <v-expansion-panel-content class="px-6">
                    <div v-for="(event, i) in viewItem.roleInfo.eventNameHistory" :key="i">
                      {{ event }}
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <p class="pa-6" v-else>No role information.</p>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
// import AddArtwork from "@/components/Platform/AddArtwork";
import StatusCodeBar from "@/components/Platform/StatusCodeBar";
import PlatformLayout from "@/layouts/Platform";
import { getAllUsers, approveUser, lockUser } from "@/services/adminService";
import { downloadFileFromBFS } from "@/services/fileService";
import MessageBox from "@/components/Common/MessageBox";
import config from "@/config.js";
import VueJsonToCsv from "vue-json-to-csv";
import AvatarFromUserId from "../../../components/Common/AvatarFromUserId.vue";
import ProtectedImageFromFileDescriptor from "../../../components/Common/ProtectedImageFromFileDescriptor.vue";
import { autoApproveUser, getAutoApproveStatus } from "../../../services/adminService";

export default {
  name: "AdminUserFunctions",
  components: {
    StatusCodeBar,
    MessageBox,
    VueJsonToCsv,
    AvatarFromUserId,
    ProtectedImageFromFileDescriptor
  },
  data: () => ({
    files: [],
    expand: false,
    openIdx: -1,
    sortBy: "createdAt",
    sortDesc: true,
    headers: [
      {
        text: "Approve/ Disapprove",
        value: "approveUser",
        align: "center",
        sortable: false,
        inputField: false,
        inTable: true
      },
      {
        text: "Lock/ Unlock",
        value: "lockUser",
        align: "center",
        sortable: false,
        inTable: true
      },
      {
        text: "User ID",
        align: "left",
        sortable: true,
        value: "userid",
        inTable: true
      },
      {
        text: "Email",
        align: "left",
        sortable: true,
        value: "email",
        inTable: true
      },
      {
        text: "Phone",
        align: "left",
        value: "phone",
        inTable: true
      },
      {
        text: "Membership",
        align: "left",
        value: "isPremium",
        inTable: true
      },
      {
        text: "Premium Expiry Date",
        align: "left",
        value: "premiumExpiryDate",
        inTable: true
      },
      {
        text: "Created At",
        align: "left",
        value: "createdAt",
        inTable: true
      },
      {
        text: "Role Type",
        align: "left",
        value: "roleType",
        inTable: true
      },
      {
        text: "Storage Quota",
        align: "left",
        value: "storageQuota",
        inTable: true
      },
      {
        text: "Approved",
        align: "left",
        value: "isApproved",
        inTable: true
      },
      {
        text: "Locked",
        align: "left",
        value: "isLocked",
        inTable: true
      },
      {
        text: "Image",
        align: "right",
        value: "image",
        inTable: false
      },
      {
        text: "FPS",
        align: "right",
        value: "roleInfo.fpsAccount",
        inTable: true
      },
      {
        text: "Bank Code",
        align: "right",
        value: "roleInfo.bankAccount.bankCode",
        inTable: true
      },
      {
        text: "Account Number",
        align: "right",
        value: "roleInfo.bankAccount.accountNumber",
        inTable: true
      },
      {
        text: "Account Name",
        align: "center",
        value: "roleInfo.bankAccount.accountName",
        inTable: true
      },
      {
        text: "Marketing Consent",
        align: "center",
        value: "roleInfo.marketingConsent",
        inTable: true
      },
      {
        text: "Role Info",
        value: "roleInfoExists",
        align: "center",
        inTable: true
      }
    ],
    csvLabels: {
      userid: { title: "User ID" },
      email: { title: "Email" },
      phone: { title: "Phone" },
      roleType: { title: "RoleType" },
      address: { title: "Address" },
      createdAt: { title: "Created At" },
      premiumExpiryDate: { title: "Premium Expiry Date" },
      storageQuota: { title: "Storage Quota" },
      isLocked: { title: "Locked" },
      isApproved: { title: "Approved" },
      fpsAccount: { title: "FPS Account" },
      bankCode: { title: "Bank Code" },
      accountNumber: { title: "Bank Account Number" },
      accountName: { title: "Bank Account Name" }
    },
    statusCode: [
      {
        label: "AN",
        roleType: 0,
        color: "#2EB73A"
      },
      {
        label: "Premier Member",
        roleType: 2,
        color: "#B3A7D3"
      },
      {
        label: "Member",
        roleType: 1,
        color: "#F1951B"
      }
    ],
    users: [],
    addrList: {},
    editedIndex: -1,
    timer: null,
    searchField: null,
    searchInput: null,
    addingArtwork: false,
    pageNumber: 1,
    itemsPerPage: 5,
    pageCount: 1,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    dialog: false,
    viewItem: "",
    autoApprove: false,
    autoApproveDialog: false,
    password: "",
    show1: false
  }),
  computed: {
    userJson() {
      return this.users.map((elem) => {
        elem.premiumExpiryDate = new Date(elem.premiumExpiryDate).toISOString().substr(0, 10);
        if (!Object.hasOwnProperty.call(elem.roleInfo, "fpsAccount")) {
          elem.fpsAccount = "";
        } else {
          elem.fpsAccount = elem.roleInfo.fpsAccount;
        }
        if (!Object.hasOwnProperty.call(elem.roleInfo, "bankAccount")) {
          elem.bankCode = "";
          elem.accountNumber = "";
          elem.accountName = "";
          elem.marketingConsent = "";
        } else {
          elem.bankCode = elem.roleInfo.bankAccount.bankCode;
          elem.accountNumber = elem.roleInfo.bankAccount.accountNumber;
          elem.accountName = elem.roleInfo.bankAccount.accountName;
          elem.marketingConsent = elem.roleInfo.marketingConsent;
        }
        return elem;
      });
    }
  },
  mounted() {
    this.initialize();
  },
  async created() {
    this.$emit(`update:layout`, PlatformLayout);
    const role = this.$session.get("role");
    if (role !== "AN") {
      this.$router.push({ name: "Not Found" });
      return;
    }

    this.role = this.$session.get("role");
    if (this.users.length === 0 && this.role === "member") {
      this.timer = setInterval(() => {
        this.role = this.$session.get("role");

        if (this.role !== "member") {
          clearInterval(this.timer);
          this.initialize();
        }
      }, 1000);
    } else {
      // to deal with refresh
      clearInterval(this.timer);
      this.initialize();
    }
  },
  updated() {
    // this.role = this.$session.get('role')
  },
  methods: {
    getStatusCode(item) {
      return this.statusCode.find((obj) => obj.roleType === item.roleType);
    },
    getStatusColor(item) {
      let status = this.statusCode.find((obj) => obj.roleType === item.roleType);
      return "color: " + status.color + "; margin: 0;";
    },
    async initialize() {
      //  Get request to server
      const token = this.$session.get("token");
      const result = await getAllUsers(this.$axios, config, token);

      if (result.status === 200) {
        this.users = result.data.users.map((elem) => {
          elem.roleInfoExists = Object.getOwnPropertyNames(elem.roleInfo).length !== 1;
          return elem;
        });
        this.autoApprove = await getAutoApproveStatus(this.$axios, config, this.$session.get("token")).then((res) => {
          return res.data.enabled;
        });
      } else {
        this.messageBox = {
          content: `Failed to retrieve user information. Error code: ${result.status}. Error message: ${result.message}.`,
          cssClass: "error",
          state: true,
          destination: ""
        };
      }
    },
    async approveUser(userid) {
      const token = this.$session.get("token");
      const prompt = `Confirm to approve user ${userid}?`;
      if (confirm(prompt)) {
        const keypair = this.$session.get("keypair");
        let payload = keypair;
        payload.toApprove = true;
        const result = await approveUser(this.$axios, config, userid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `User approved`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.initialize();
        } else {
          this.messageBox = {
            content: `Failed to approve user. Error code: ${result.status}. Error message: ${result.data.message}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
        }
      }
    },
    async disapproveUser(userid) {
      const token = this.$session.get("token");
      const prompt = `Confirm to disapprove user ${userid}?`;
      if (confirm(prompt)) {
        const keypair = this.$session.get("keypair");
        let payload = keypair;
        payload.toApprove = false;
        const result = await approveUser(this.$axios, config, userid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `User disapproved`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.initialize();
        } else {
          this.messageBox = {
            content: `Failed to disapprove user. Error code: ${result.status}. Error message: ${result.data.message}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
        }
      }
    },
    async lockUser(userid) {
      const token = this.$session.get("token");
      const prompt = `Confirm to lock user ${userid}?`;
      if (confirm(prompt)) {
        const keypair = this.$session.get("keypair");
        let payload = keypair;
        payload.toLock = true;
        const result = await lockUser(this.$axios, config, userid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `User locked`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.initialize();
        } else {
          this.messageBox = {
            content: `Failed to lock user. Error code: ${result.status}. Error message: ${result.data.message}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
        }
      }
    },
    async unlockUser(userid) {
      const token = this.$session.get("token");
      const prompt = `Confirm to unlock user ${userid}?`;
      if (confirm(prompt)) {
        const keypair = this.$session.get("keypair");
        let payload = keypair;
        payload.toLock = false;
        const result = await lockUser(this.$axios, config, userid, token, payload);
        if (result.status === 200) {
          this.messageBox = {
            content: `User unlocked`,
            cssClass: "primary",
            state: true,
            destination: ""
          };
          this.initialize();
        } else {
          this.messageBox = {
            content: `Failed to unlock user. Error code: ${result.status}. Error message: ${result.data.message}. `,
            cssClass: "primary",
            state: true,
            destination: ""
          };
        }
      }
    },
    setAutoApprove() {
      autoApproveUser(this.$axios, config, this.autoApprove, this.password, this.$session.get("token"))
        .then((res) => {
          if (res.status === 200) {
            this.messageBox = {
              content: res.data,
              state: true,
              cssClass: "message"
            };
          } else {
            this.messageBox = {
              content: res.data.message,
              state: true,
              cssClass: "error"
            };
          }
        })
        .then(async () => {
          this.autoApprove = await getAutoApproveStatus(this.$axios, config, this.$session.get("token")).then((res) => {
            return res.data.enabled;
          });
          this.autoApproveDialog = false;
          this.password = "";
        });
    },
    dismissAutoApprove() {
      this.autoApprove = getAutoApproveStatus(this.$axios, config, this.$session.get("token")).then((res) => {
        return res.data.enabled;
      });
      this.autoApproveDialog = false;
    },
    async downloadImage(fileDescriptor) {
      const result = await downloadFileFromBFS(this.$axios, config, fileDescriptor);
      if (result.status === 200) {
        const blob = result.data;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileDescriptor.fileName;
        link.click();
        URL.revokeObjectURL(link.href);
      }
    },
    viewRoleInfo(item) {
      this.dialog = true;
      this.viewItem = item;
      try {
        if (this.viewItem.roleInfo !== "") {
          this.viewItem.roleInfo = JSON.parse(this.viewItem.roleInfo);
        }
      } catch {
        (e) => e;
      }
    },
    idTypeItems(i) {
      let labels = this.translateArray("profilePersonIDTypeOption");
      labels.push("Business Registration");
      return labels[i];
    },
    translateArray(item) {
      const content = this.$t(item);
      const result = content.replace(/['"]+/g, "").replace(/['“]+/g, "").replace(/['”]+/g, "").split(",");
      return result;
    }
  }
};
</script>
