<template>
  <div style="display: flex; align-items: center">
    <v-avatar v-if="!loading" :class="{ grey: true, 'lighten-2': iconPhotoPresent }" :size="size">
      <v-img :src="link" alt="" aspect-ratio="1" v-if="iconPhotoPresent" />
      <!-- <h3 class="white--text ma-0" style="font-size: 24px; font-weight: 600" v-else>{{ useridSubstring }}</h3> -->
      <v-icon v-else color="white" :size="size * 0.7">mdi-account</v-icon>
    </v-avatar>
    <v-skeleton-loader type="text" v-else width="100px"></v-skeleton-loader>
  </div>
</template>

<script>
import config from "@/config";
import { downloadFileFromBFS } from "@/services/fileService";
import { getPublicRoleInfo } from "@/services/authService";
import { getUseridFromAddress } from "../../services/authService";
export default {
  props: { userid: { type: String }, size: { default: "30", type: String }, address: { type: String } },
  data: function () {
    return { link: null, iconPhotoPresent: false, loading: false, useridFromAddress: null };
  },
  async created() {
    if (this.address) {
      this.useridFromAddress = await getUseridFromAddress(this.$axios, config, this.address).then((res) => {
        return res.data.userid;
      });
    }
    // if (!this.userid && !this.useridFromAddress) {
    //   this.link = "No image";
    //   return;
    // }
    let publicRoleInfoResponse = this.findPublicRoleInfoFromState(this.address, this.userid);
    let roleInfoExist;
    if (publicRoleInfoResponse) {
      roleInfoExist = true;
    } else {
      if (this.userid) {
        publicRoleInfoResponse = await getPublicRoleInfo(this.$axios, config, this.userid);
      } else {
        publicRoleInfoResponse = await getPublicRoleInfo(this.$axios, config, this.useridFromAddress);
      }
    }

    let fileDescriptor;
    if (publicRoleInfoResponse.status === 200) {
      if (!roleInfoExist) {
        this.$store.commit("addUseridRoleInfoPair", {
          userid: this.userid,
          address: this.address,
          publicRoleInfo: publicRoleInfoResponse.data.publicRoleInfo
        });
      }
      if (Object.hasOwnProperty.call(publicRoleInfoResponse.data.publicRoleInfo, "iconPhoto")) {
        this.iconPhotoPresent = true;
        fileDescriptor = publicRoleInfoResponse.data.publicRoleInfo.iconPhoto.fileDescriptor;
        if (Object.hasOwnProperty.call(fileDescriptor, "thumbnail")) {
          fileDescriptor = fileDescriptor.thumbnail;
        }
      } else {
        this.iconPhotoPresent = false;
        return;
      }
    }

    if (!fileDescriptor) {
      return;
    }

    let imageFile = this.$store.getters.getImageFile(fileDescriptor.fileid);

    if (imageFile) {
      this.link = imageFile.url;
      return;
    }

    let result = await downloadFileFromBFS(this.$axios, config, fileDescriptor)
      .then((res) => {
        if (res.status === 200) {
          let src = URL.createObjectURL(res.data);
          this.$store.commit("addImageFile", {
            fileid: fileDescriptor.fileid,
            url: src
          });
          this.iconPhotoPresent = true;
          return src;
        } else {
          this.iconPhotoPresent = false;
          return "No image";
        }
      })
      .catch(() => {
        this.iconPhotoPresent = false;
        return "No image";
      });

    this.link = result;
    this.loading = false;
  },
  methods: {
    findPublicRoleInfoFromState(address, userid) {
      let output = {
        data: {
          publicRoleInfo: null
        },
        status: null
      };
      if (address) {
        const useridRoleInfoPair = this.$store.state.useridRoleInfoPair;
        const result = useridRoleInfoPair.find((elem) => {
          return elem.address === address;
        });
        if (result) {
          output.data.publicRoleInfo = result;
          output.status = 200;
        } else {
          return false;
        }
      }

      if (userid) {
        const useridRoleInfoPair = this.$store.state.useridRoleInfoPair;
        const result = useridRoleInfoPair.find((elem) => {
          return elem.userid === userid;
        });
        if (result) {
          output.data.publicRoleInfo = result;
          output.status = 200;
        } else {
          return false;
        }
      }
      return output;
    }
  }
};
</script>
