<template>
  <div id="statusCodeContainer">
    <div id="statusCode" v-for="(item, i) in code" :key="i">
      <v-avatar :color="item.color" v-if="$screen.width > 600" size="24"> </v-avatar>
      <v-avatar :color="item.color" v-else size="10"> </v-avatar>
      <p id="label">{{ item.label }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["code"]
};
</script>
