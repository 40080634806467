<template>
  <v-img :src="src" aspect-ratio="1" class="grey lighten-2" min-height="150px" contain>
    <template v-slot:default>
      <v-img v-if="src === 'No image' && !large" aspect-ratio="1" class="grey lighten-2">
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-icon>mdi-cloud-off-outline</v-icon>
        </v-row>
      </v-img>
    </template>
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
import config from "@/config";
import { downloadProtectedFileFromBFS } from "@/services/fileService";

export default {
  props: ["fileDescriptor"],

  data: function () {
    return { src: null, large: false, timeInterval: null };
  },
  async created() {
    if (this.fileDescriptor === "No image" || !this.fileDescriptor) {
      this.src = "No image";
      return;
    }
    const token = this.$session.get("token");
    const keypair = this.$session.get("keypair");
    downloadProtectedFileFromBFS(this.$axios, config, this.fileDescriptor, token, keypair)
      .then(async (res) => {
        if (res.status === 200) {
          this.src = URL.createObjectURL(res.data);
        } else {
          alert(await res.data.text());
          this.src = "No image";
        }
      })
      .catch(() => {
        this.src = "No image";
      });
  }
};
</script>
